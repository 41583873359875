import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { useFirebase } from "react-redux-firebase"

export default function LogoutPage() {
  const firebase = useFirebase()

  useEffect(() => {
    handleLogout()
  }, [])

  const handleLogout = () => {
    firebase.logout()
    window.sessionStorage.clear()
    navigate("/login/")
  }

  return null
}
